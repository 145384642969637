import {get} from "./vrexAuth"

class BimtrackApi {

    static async getHubs() {
        return await get(process.env.REACT_APP_INTEGRATION_PROXY_URL+"/bimtrack/v3/hubs");
    }

    static async getProjects(hubId) {
        return await get(process.env.REACT_APP_INTEGRATION_PROXY_URL + "/bimtrack/v3/hubs/" + hubId + "/projects");
    }

    static async getModels(hubId, projectId) {
        return await get(process.env.REACT_APP_INTEGRATION_PROXY_URL + "/bimtrack/v3/hubs/" + hubId + "/projects/" + projectId + "/models");
    }
}

export default BimtrackApi;

import {get} from "./vrexAuth";

class BimsyncApi {

    static async getProjects() {
        return await get(process.env.REACT_APP_INTEGRATION_PROXY_URL+"/bimsync/v2/projects");
    }

    static async getModels(projectId) {
        return await get(process.env.REACT_APP_INTEGRATION_PROXY_URL+"/bimsync/v2/projects/" + projectId + "/models");
    }
}

export default BimsyncApi;

import {get} from "./vrexAuth";
import {proxyEncode} from "../common/util";

class BuildagilApi {

    static async getProjects() {
        return await get(process.env.REACT_APP_INTEGRATION_PROXY_URL + "/buildagil/bcf/3.0/projects");
    }

    static async getModels(projectId) {
        return await get(proxyEncode(process.env.REACT_APP_INTEGRATION_PROXY_URL + "/buildagil/bcf/3.0/projects/" + projectId + "/documents?$filter=(file_type eq 'ifc' or file_type eq 'e57')"));
    }
}

export default BuildagilApi;

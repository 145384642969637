import {del, get, post, patch, put} from "./vrexAuth";

class VrexApi {

    static async getUser() {
        return await get(process.env.REACT_APP_API_URL + "/users/me");
    }

    static async getProjects(showAll) {
        let filter = showAll ? "?includeNonMemberProjects=true&includeInactiveProjects=true" : ""
        return await get(process.env.REACT_APP_API_URL + "/projects" + filter);
    }

    static async getOrganizationProjects(organizationId) {
        return await get(process.env.REACT_APP_API_URL + "/organizations/" + organizationId + "/projects");
    }

    static async getOrganizations() {
        return await get(process.env.REACT_APP_API_URL + "/organizations");
    }

    static async getProject(projectId) {
        return await get(process.env.REACT_APP_API_URL + "/projects/" + projectId);
    }

    static async postProject(organizationId, project) {
        return await post(process.env.REACT_APP_API_URL + "/projects", project);
    }

    static async patchProject(projectId, project) {
        return await patch(process.env.REACT_APP_API_URL + "/projects/" + projectId, project);
    }

    static async deleteProject(projectId) {
        return await del(process.env.REACT_APP_API_URL + "/projects/" + projectId);
    }

    static async deleteProjectActivation(projectId) {
        return await del(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/activation");
    }

    static async postProjectActivation(projectId) {
        return await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/activation", null);
    }

    static async postProjectUser(projectId, user) {
        return await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/users", user);
    }

    static async postProjectInvitation(projectId, invitation) {
        return await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/users/invitations", invitation);
    }

    static async getProjectUsers(projectId) {
        return await get(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/users");
    }

    static async postProjectRoom(projectId, room) {
        return await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/rooms", room);
    }

    static async deleteProjectRoom(projectId, roomId) {
        return await del(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/rooms/" + roomId);
    }

    static async getProjectRooms(projectId) {
        return await get(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/rooms");
    }

    static async getModelSources(projectId) {
        return await get(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/model-sources");
    }

    static async postUpload(projectId, modelSourceId, upload) {
        return await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/local/" + modelSourceId + "/uploads", upload);
    }

    static async getIntegration(projectId, integrationId, includeModels = true) {
        return await get(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/integrations/" + integrationId + "?includeModels=" + includeModels);
    }

    static async postIntegration(projectId, integration) {
        return await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/integrations", integration);
    }

    static async postIntegrationModel(projectId, integrationId, model) {
        return await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/integrations/" + integrationId + "/models", model);
    }

    static async postProcessRequest(projectId, integrationId) {
        return await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/integrations/" + integrationId + "/process-requests", null);
    }

    static async deleteModelSource(projectId, modelSourceId) {
        return await del(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/model-sources/" + modelSourceId);
    }

    static async patchModelSource(projectId, modelSourceId, modelSource) {
        return await patch(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/model-sources/" + modelSourceId, modelSource);
    }

    static async deleteModel(projectId, modelSourceId, modelId) {
        return await del(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/model-sources/" + modelSourceId + "/models/" + modelId);
    }

    static async deleteModels(projectId, modelSourceId) {
        return await del(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/model-sources/" + modelSourceId + "/models" );
    }

    static async postAcceptProjectInvitation(invitationId) {
        return await post(process.env.REACT_APP_API_URL + "/users/me/project-invitations/" + invitationId + "/accept", null);
    }

    static async postDeclineProjectInvitation(invitationId) {
        return await post(process.env.REACT_APP_API_URL + "/users/me/project-invitations/" + invitationId + "/decline", null);
    }

    static async deleteProjectUser(projectId, userId) {
        return await del(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/users/" + userId);
    }

    static async patchProjectUser(projectId, userId, user) {
        return await patch(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/users/" + userId, user);
    }

    static async deleteProjectInvitation(projectId, invitationId) {
        return await del(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/users/invitations/" + invitationId);
    }

    static async getOrganization(organizationId) {
        return await get(process.env.REACT_APP_API_URL + "/organizations/" + organizationId);
    }

    static async deleteOrganizationUser(organizationId, userId) {
        return await del(process.env.REACT_APP_API_URL + "/organizations/" + organizationId + "/users/" + userId);
    }

    static async deleteOrganizationInvitation(organizationId, invitationId) {
        return await del(process.env.REACT_APP_API_URL + "/organizations/" + organizationId + "/users/invitations/" + invitationId);
    }

    static async patchOrganizationUser(organizationId, userId, user) {
        return await patch(process.env.REACT_APP_API_URL + "/organizations/" + organizationId + "/users/" + userId, user);
    }

    static async postOrganizationInvitation(organizationId, invitation) {
        return await post(process.env.REACT_APP_API_URL + "/organizations/" + organizationId + "/users/invitations", invitation);
    }

    static async postAcceptOrganizationInvitation(invitationId) {
        return await post(process.env.REACT_APP_API_URL + "/users/me/organization-invitations/" + invitationId + "/accept", null);
    }

    static async postDeclineOrganizationInvitation(invitationId) {
        return await post(process.env.REACT_APP_API_URL + "/users/me/organization-invitations/" + invitationId + "/decline", null);
    }

    static async deleteExternalAccess(projectId, roomId) {
        return await del(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/rooms/" + roomId + "/external-access");
    }

    static async postExternalAccess(projectId, roomId) {
        return await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/rooms/" + roomId + "/external-access", null);
    }

    static async patchRoom(projectId, roomId, room) {
        return await patch(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/rooms/" + roomId, room);
    }

    static async postLocalConvertedModelSource(projectId, modelSource) {
        return await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/local-converted", modelSource);
    }

    static async postLocalModelSource(projectId, modelSource) {
        return await post(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/contents/local", modelSource);
    }

    static async putProjectRotation(projectId, rotation) {
        return await put(process.env.REACT_APP_API_URL + "/projects/" + projectId + "/rotation", rotation)
    }
}

export default VrexApi

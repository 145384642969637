import React, {useCallback, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import VrexApi from "../../../api/vrexApi";
import {useTranslation} from "react-i18next";
import {AddIntegration} from "../AddIntegration";
import AconexApi from "../../../api/aconexApi";
import {AconexConfig} from "../../../common/integrationConfig";
import {Box, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import Link from "@mui/material/Link";

const Instances = Object.freeze([
    {value: "ca1", name: "Canada (CA1)"},
    {value: "uk1", name: "Canada (UK1)"},
    {value: "us1", name: "US & Latin America (US1)"},
    {value: "uk1", name: "United Kingdom (UK1)"},
    {value: "eu1", name: "Europe (EU1)"},
    {value: "uk1", name: "Europe (UK1)"},
    {value: "ksa1", name: "Saudi Arabia (KSA1)"},
    {value: "mea", name: "Rest of Middle East (MEA)"},
    {value: "uk1", name: "Africa (UK1)"},
    {value: "asia1", name: "Japan (ASIA1)"},
    {value: "asia2", name: "Singapore (ASIA2)"},
    {value: "au1", name: "Australia / NZ (AU1)"},
    {value: "eu1", name: "ALDAR (EU1)"},
    {value: "demo1", name: "Demo Lab"},
])

export function AconexAddIntegration({projectId, onIntegrationAdded}) {
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();
    const [instance, setInstance] = useState({name: "", value: ""});
    const theme = useTheme();

    const updateInstance = useCallback((instanceShort) => {
        let i = getInstance(instanceShort);
        if (i.value) {
            navigate(location.pathname + "?instance=" + instanceShort, {replace: true});
        } else {
            navigate(location.pathname, {replace: true});
        }
        setInstance(i);
    }, [navigate, location.pathname])

    useEffect(() => {
        let is = new URLSearchParams(location.search).get("instance");
        updateInstance(is);
    }, [location.search, updateInstance])

    function getInstance(instance) {
        for (let i of Instances) {
            if (i.value === instance) {
                return i;
            }
        }
        return {value: "", name: ""};
    }

    async function fetchRootNodes() {
        const response = await AconexApi.getProjects(instance.value);
        return response.searchResults.map(project => mapProject(project));
    }

    function mapProject(project) {
        return ({
            id: project.projectID.toString(),
            name: project.projectShortName,
            selected: false,
            isLeaf: true,
        });
    }

    async function handleSave(project) {
        let aconex = await VrexApi.postIntegration(projectId, {
            type: "ACONEX",
            externalData: instance.value,
            externalProjectId: project.id,
            name: project.name
        })
        onIntegrationAdded(aconex);
        navigate("/projects/" + projectId + "/models/aconex/" + aconex.id);
    }

    return (
        <Box>
            {!instance.value &&
                <React.Fragment>
                    <Typography variant={"body2"} color={"textSecondary"}
                                sx={{marginBottom: 2}}>{t('project.models.aconex.add.location.select', { name: AconexConfig.name })}</Typography>
                    <FormControl fullWidth>
                        <InputLabel id="region-select-label">{t('project.models.aconex.add.instance')}</InputLabel>
                        <Select
                            labelId="region-select-label"
                            id="region-select"
                            value={instance.value}
                            onChange={event => updateInstance(event.target.value)}
                            variant={"standard"}
                        >
                            {Instances.map((instance, index) => <MenuItem key={index}
                                                                          value={instance.value}>{instance.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </React.Fragment>
            }
            {instance.value &&
                <React.Fragment>
                    <Typography variant={"body2"} style={{marginBottom: theme.spacing(2)}}
                                color={"textSecondary"}>{t('project.models.aconex.add.location.change')}<Link
                        onClick={() => updateInstance("")}
                        style={{cursor: "pointer"}}
                        underline="hover">{instance.value.toUpperCase()}</Link>
                    </Typography>
                    <AddIntegration projectId={projectId}
                                    integrationConfig={AconexConfig}
                                    fetchRootNodes={fetchRootNodes}
                                    saveIntegration={handleSave}
                                    integrationType={"aconex/" + instance.value}/>
                </React.Fragment>
            }
        </Box>
    );
}

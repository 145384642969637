import React, {useState} from "react";
import VrexApi from "../../../api/vrexApi";
import {IntegrationBrowser} from "../IntegrationBrowser";
import AconexApi from "../../../api/aconexApi";
import { AconexConfig } from "../../../common/integrationConfig";

export function AconexBrowser({projectId, onModelAdded}) {
    const [integration, setIntegration] = useState(null);

    async function getIntegration(projectId, integrationId) {
        let integration = await VrexApi.getIntegration(projectId, integrationId);
        setIntegration(integration);
        return integration;
    }

    async function fetchRootNodes(integration) {
        const response = await AconexApi.getDocuments(integration.externalData, integration.externalProjectId);
        let xml = new window.DOMParser().parseFromString(response, "text/xml");
        let documents = xml.getElementsByTagName("Document");
        let items = []
        for (let document of documents) {
            items.push(mapItem(document, integration.models))
        }
        return items;
    }

    function mapItem(document, existingModels) {
        let id = document.getAttribute("DocumentId");
        return {
            id: id,
            name: document.getElementsByTagName("Filename")[0].textContent,
            isLeaf: true,
            selected: false,
            disabled: existingModels.find(model => model.externalId === id),
        }
    }

    return (
        <IntegrationBrowser projectId={projectId}
                            integrationConfig={AconexConfig}
                            fetchRootNodes={fetchRootNodes}
                            fetchIntegration={getIntegration}
                            onModelAdded={onModelAdded}
                            integrationType={"aconex/" + integration?.externalData}/>
    )
}

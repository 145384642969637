import {get} from "./vrexAuth";

import packageInfo from '../../package.json';

const headers = {
    'X-Client-Version': packageInfo.version
}

class BimplusApi {

    static async getProjects() {
        return await get(
            process.env.REACT_APP_INTEGRATION_PROXY_URL + "/bimplus/v2/projects",
            headers
        );
    }

    static async getModels(teamId, projectId) {
        return await get(
            process.env.REACT_APP_INTEGRATION_PROXY_URL + "/bimplus/v2/" + teamId + "/projects/" + projectId + "/divisions",
            headers
        );
    }
}

export default BimplusApi;

import {get} from "./vrexAuth";

const headers = {accept:"application/vnd.api+json"};

class StreamBimApi {

    static async getProjects() {
        return await get(process.env.REACT_APP_INTEGRATION_PROXY_URL+"/streambim/mgw/api/v3/project-links?filter[active]=true", headers);
    }

    static async getBuildings(projectId) {
        return await get(process.env.REACT_APP_INTEGRATION_PROXY_URL+"/streambim/project-"+ projectId +"/api/v1/v2/buildings", headers);
    }

    static async getModels(projectId, buildingId) {
        return await get(process.env.REACT_APP_INTEGRATION_PROXY_URL+"/streambim/project-"+ projectId +"/api/v1/v2/ifc-files?filter%5Bbuilding%5D="+buildingId, headers);
    }
}

export default StreamBimApi;

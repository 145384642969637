import {handleResponse} from "../common/util";

async function get(url, headers = null) {
    const response = await fetch(url, {
        headers: {
            Accept: "application/json",
            ...headers,
        }
    });
    return await handleResponse(response)
}

export class OpenApi {
    static async getConverters() {
        return await get(process.env.REACT_APP_OPEN_API_URL + "/converters");
    }
}

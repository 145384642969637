import {Auth} from "aws-amplify";
import {handleResponse} from "../common/util";


export async function get(url, headers = null) {
    return request('GET', url, null, headers);
}

export async function post(url, body, headers = null) {
    return await request('POST', url, body, headers);
}

export async function put(url, body, headers = null) {
    return await request('PUT', url, body, headers);
}

export async function patch(url, body, headers = null) {
    return await request('PATCH', url, body, headers);
}

async function request(method, url, body, headers) {
    const response = await fetch(url, {
        method: method,
        headers: {
            Accept: "application/json",
            Authorization: await getToken(),
            ...headers,
        },
        body: body !== null ? JSON.stringify(body) : null
    });
    return await handleResponse(response);
}

export async function del(url, headers = null) {
    const response = await fetch(url, {
        method: "delete",
        headers: {
            Authorization: await getToken(),
            ...headers,
        },
    });
    return await handleResponse(response);
}

export async function getToken() {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
}


export async function logout() {
    let session = await Auth.currentSession();
    let refreshToken = session.getRefreshToken();
    let body = {
        "ClientId": process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
        "Token": refreshToken.getToken()
    }
    await fetch("https://cognito-idp.eu-central-1.amazonaws.com/", {
        method: "post",
        headers: {
            "content-type": "application/x-amz-json-1.1",
            "X-Amz-Target": "AWSCognitoIdentityProviderService.RevokeToken"
        },
        body: JSON.stringify(body)
    });
    await Auth.signOut();
}

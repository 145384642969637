import {get} from "./vrexAuth"

class Bim360Api {

    static async getHubs() {
        return await get(process.env.REACT_APP_INTEGRATION_PROXY_URL+"/bim360/project/v1/hubs")
    }

    static async getProjects(hubId) {
        return await get(process.env.REACT_APP_INTEGRATION_PROXY_URL+"/bim360/project/v1/hubs/" + hubId + "/projects")
    }

    static async getTopFolders(hubId, projectId) {
        return await get(process.env.REACT_APP_INTEGRATION_PROXY_URL+"/bim360/project/v1/hubs/" + hubId + "/projects/" + projectId + "/topFolders")
    }

    static async getFolderContent(projectId, folderId) {
        return await get(process.env.REACT_APP_INTEGRATION_PROXY_URL+"/bim360/data/v1/projects/" + projectId + "/folders/" + encodeURIComponent(folderId) + "/contents")
    }
}

export default Bim360Api;
